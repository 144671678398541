import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

export function PieChart({ data , timeframe}) {
	// Calculate total hours lived
	const totalDaysLived = data.age * 365;

	// Calculate total phone hours
	const totalPhoneHours = data.phoneTime * 365 * (data.yearsPhone);
	const totalPhoneDays = Math.trunc(totalPhoneHours / 24);

	const phoneDaysThisYear = Math.trunc((data.phoneTime * 365) / 24)
	var chartData = {};
	
	const totalDaysLivedMinusPhone = Math.trunc(totalDaysLived - totalPhoneDays);
	if(timeframe === "lifetime"){
		chartData = {
			labels: ['Phone Usage (days)', 'Other Activities (days)'],
			datasets: [{
				data: [totalPhoneDays, totalDaysLivedMinusPhone],
				backgroundColor: ['#FF6384', '#36A2EB'],
			}]
		};
	}
	if (timeframe === "lastyear"){
		chartData = {
			labels: ['Phone Usage (days)', 'Other Activities (days)'],
			datasets: [{
				data: [phoneDaysThisYear, Number(365-phoneDaysThisYear)],
				backgroundColor: ['#FF6384', '#36A2EB'],
			}]
		};
	}

	const options = {
    responsive: true,
    maintainAspectRatio: true,
		plugins: {
			legend: {
				position: 'bottom',
			},
		},
	};

	return (
		<div style={{ width: '100%', maxWidth: '400px', margin: '0 auto' }}>
			<Pie data={chartData} options={options} />
		</div>
	);
}
