import React from 'react';
import { Box, Typography } from '@mui/material';

export function TimeWasted({ data }) {
    // Calculate total days spent on phone over lifetime
    const totalDaysOnPhone = Math.trunc((data.phoneTime * 365 * data.yearsPhone) / 24);
    const totalDaysLived = data.age * 365;

    const percentLifeOnPhone = Math.trunc((totalDaysOnPhone/totalDaysLived)*100)

    return (
        <div>
            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                <Typography variant='h4' color="#c7c7c7" margin={2} marginTop={10} textAlign={'center'}>Time Wasted on Phone</Typography>
                <Typography variant='body2' fontSize={18} color="#e6e6e6" textAlign={'center'}>
                    Based on your daily usage, you've spent approximately <strong>{totalDaysOnPhone} days</strong> of your life on your phone, 
                    and have wasted <strong>{percentLifeOnPhone}%</strong> of your life.
                </Typography>
            </Box>
        </div>
    );
}
