import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Create a styled version of TextField
const StyledTextField = styled(TextField)({
	'& .MuiInputBase-root': {
		backgroundColor: '#1e1e1e', // Dark background for input
		borderRadius: '4px',
		color: '#ffffff', // White text color
	},
	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: '#B0B0B0', // Border color
	},
	'&:hover .MuiOutlinedInput-notchedOutline': {
		borderColor: '#B0B0B0', // Border color on hover
	},
	'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
		borderColor: '#B0B0B0', // Border color when focused
	},
	'& input': {
		color: '#ffffff', // Input text color
	},
});

export function TimeInputForm({ onSubmit, showControls }) {
	const [phoneTime, setPhoneTime] = useState(0.0);
	const [age, setAge] = useState(0);
	const [yearsPhone, setYearsPhone] = useState(0);
	const [error, setError] = useState('');

	const handleSubmit = (e) => {
	e.preventDefault();
	
	if (Number(yearsPhone) > Number(age)) {
		setError("Years you've had a phone cannot be more than your age.");
		return;
	}
	
	if (Number(phoneTime) <= 0 || Number(age) <= 0 || Number(yearsPhone) <= 0){
		setError("Input values must be greater than zero")
		return
	}

	setError(''); // Clear any previous errors
	onSubmit({ phoneTime: Number(phoneTime), age: Number(age), yearsPhone: Number(yearsPhone)});
	
	// Hide inputs after submission
	};

	return (
		<form onSubmit={handleSubmit}>
			{showControls && (
				<Box display="flex" flexDirection="row" justifyContent="center" gap={2}>
					<StyledTextField
					type="number"
					label="Daily phone time (hours):"
					placeholder="Enter hours"
					value={phoneTime}
					onChange={(e) => setPhoneTime(e.target.value)}
					variant="outlined"
					sx={{ width: '200px' }}
					slotProps={{
						inputLabel: { style: { color: '#888888' }},
					}}
					/>
					<StyledTextField
						type="number"
						label="Age"
						placeholder="Enter age"
						value={age}
						onChange={(e) => setAge(e.target.value)}
						variant="outlined"
						sx={{ width: '200px' }}
						slotProps={{
							inputLabel: { style: { color: '#888888' }},
						}}
					/>
					<StyledTextField
						type="number"
						label="Years you've had a phone"
						placeholder="Enter years"
						value={yearsPhone}
						onChange={(e) => setYearsPhone(e.target.value)}
						variant="outlined"
						sx={{ width: '200px' }}
						slotProps={{
							inputLabel: { style: { color: '#888888' }},
						}}
					/>
				</Box>
			)}
			{error && (
				<Box mt={1} textAlign="center">
					<Typography variant="body2" color="error">{error}</Typography>
				</Box>
			)}
			<Box display="flex" justifyContent={"center"} marginTop={2} maxHeight={"50px"}>
			{showControls && (
				<Button type="submit" variant="contained" sx={{ backgroundColor: '#888888' }}>
					Calculate
				</Button>
			)}
			</Box>
		</form>
	);
}
