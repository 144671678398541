import React, { useState } from 'react';
import { TimeInputForm } from './components/TimeInputForm';
import { PieChart } from './components/PieChart';
import { TimeWasted } from './components/TimeWasted';
import { Box, Typography, Button } from '@mui/material';
import './App.css';

function App() {
	const [timeData, setTimeData] = useState(null);
	const [showGraphs, setShowGraphs] = useState(false); // State to control visibility of graphs

	const handleSubmit = (data) => {
		setTimeData(data);
		setShowGraphs(true); // Show graphs when data is submitted
	};

	const handleCalculateAgain = () => {
		setTimeData(null); // Reset time data
		setShowGraphs(false); // Hide graphs when calculating again
	};

	return (
		<div>
			<Box mb={2} marginTop={3} justifyContent={"center"}>
				<Typography textAlign="center" color='white' variant="h2" marginBottom={2}>Wasted Time</Typography>
				<Typography 
					textAlign="center" 
					color='grey' 
					variant="body1"
					sx={{ maxWidth: '700px', margin: '0 auto', padding: '0 16px' }} // Limit width and add padding
				>
					This calculator is designed to take in a few details about your life, and output some interesting visualizations regarding your phone usage.<br />
					For a lot of people, the amount of time that they spend on their phone may be shocking, and this visualization is intended to make you think about the time you spend on your phone.
				</Typography>
				{!showGraphs &&
					<Typography textAlign={"center"} color='#c4c4c4' variant='h5' marginTop={2}>
						Please enter your data: 
					</Typography>
				}
			</Box>
			<TimeInputForm onSubmit={handleSubmit} showControls={!showGraphs}/>
			{showGraphs && timeData && (
				<>
					<Box display="flex" flexDirection={"row"} marginTop={1} justifyContent={"center"}>
						<Box display="flex" flexDirection={"column"} margin={0} width="400px" height="400px">
							<Typography variant='h5' color="white" textAlign={"center"} marginBottom={1.5}>Lifetime Estimate</Typography>
							<PieChart data={timeData} timeframe={"lifetime"} />
						</Box>
						<Box display="flex" flexDirection={"column"} marginTop={1} width="400px" height="400px">
							<Typography variant='h5' textAlign={"center"} color="white" marginBottom={1.5}>Yearly Estimate</Typography>
							<PieChart data={timeData} timeframe={"lastyear"} />
						</Box>
					</Box>
					<Box>
						<TimeWasted data={timeData} />
					</Box>
				</>
			)}
			{showGraphs && (
				<Box mt={2} display="flex" justifyContent="center" marginTop={3}>
					<Button variant="outlined" sx={{ backgroundColor: '#f0f0f0', color: '#000000' }} onClick={handleCalculateAgain}>
						Calculate Again?
					</Button>
				</Box>
			)}
		</div>
	);
}

export default App;
